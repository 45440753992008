import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Anfahrt = () => (
  <Layout>
    <SEO
      title="Anfahrt"
      description="Unser Kampfsport und Yoga Studio in München Feldmoching ist leicht öffentlich und mit Rad oder Auto zu erreichen! Hier findest Du die Informationen.
"
    />

      <h1>Anfahrt</h1>
      <p>Adresse:<br />Feldmochinger Straße 349 a<br />80995 München</p>
      <p>Anfahrt<br />Mit dem Auto:<br />Mittlerer Ring, BMW-Welt,<br />Lerchenauer Straße Richtung Norden,<br />nach 5 km links in die Georg-Zech-Allee (Kostenlose Parkmöglichkeiten),<br />dann rechts in die Feldmochinger Straße</p>
      <p>Wir haben zwei Parkplätze in der Einfahrt bei 'Haca Leitern'. Bitte nicht in die Einfahrt mit unserem Schild fahren. Auf der Seite mit unserem Schild ist unser Eingang, aber die Parkplätze sind auf der anderen Seite.</p>
      <p>Mit der U-Bahn:<br />U2, Richtung „Feldmoching“,<br />Haltestelle „Feldmoching Bahnhof“,<br />weiter mit dem Bus Linie 173</p>
      <p>Mit der S-Bahn:<br />S1, Haltestelle „Feldmoching Bahnhof“, weiter mit dem Bus Linie 173</p>
      <p>Mit dem Bus:<br />Linie 173, Haltestelle „Bergwachtstraße“ (3 Minuten),<br />dann zu Fuß ca. 3 Minuten, 270 m</p>
  </Layout>
)

export default Anfahrt
